// Default variable overrides
@import './bootstrap.scss';

html,
body {
  height: 100%;
}

/*
  Pour les smartphones
 */
@include media-breakpoint-down(md) {

  .inlineheader {
    display:inline;
  }
  body {
    background-color: #f0f0f0 !important;
  }
  .mainlogo {
    margin-top: 40px;
  }
  form {
    margin: 20px;
  }

  .loginbtn {
    text-align: center;
  }

  .divcontent hr {
    width: 50%;
    margin: auto;
  }
  .logoecole {
    width: 200px;
  }
  nav div hr {
    @include visually-hidden;
  }
}

.loginbtn button {
  transition: all 0.2s;
  transition-timing-function: ease-in-out;
}
@include media-breakpoint-up(md) {


  .mainlogo {
    margin-bottom: 20px;
  }

  body {
    background: #707070 !important;;
    min-height:100%;

    padding-top: 40px;
    padding-bottom: 40px;
  }


  .divcontent {
    border-left: solid 1px black;
  }

  .containeur {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    background-color: #f0f0f0;
    max-width: 900px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: auto;
  }
  .flexmode {
    display: flex !important;
  }
  form {
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
  }
}

